import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { createGlobalStyle } from 'styled-components';

const StyledGlobalStyle = createGlobalStyle<{ fonts: any }>`
  @font-face {
      font-family: "Helvetica Font";
      src: url(${({ fonts: [font] }) => font.publicURL}) format("woff");
      font-weight: 400;
  }

  @font-face {
      font-family: "Helvetica Font";
      src: url(${({ fonts: [, font] }) => font.publicURL}) format("woff");
      font-weight: 700;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html {
    font-family: "Helvetica";

    &.scroll {
      overflow-y: scroll;
    }
  }

  body {
    margin: 0;
    overflow-y: scroll;
    background: #000;
    color: #fff;

    &.noScroll {
      overflow: hidden;
      height: 100vh;
    }
  }

  a {
    color: #fff;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: #222;
  }

  ::-webkit-scrollbar-thumb {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #ddd;
  }
`;

const query = graphql`
  {
    allFile(filter: { name: { regex: "/helvetica/" } }) {
      nodes {
        publicURL
      }
    }
  }
`;

function GlobalStyle() {
  const {
    allFile: { nodes: fonts },
  } = useStaticQuery(query);

  return <StyledGlobalStyle fonts={fonts} />;
}

export default GlobalStyle;
