import React, { useState, createContext } from 'react';
import GlobalStyle from 'theme/GlobalStyle';

interface MainLayoutProps {
  children: React.ReactNode;
}

export interface StateProps {
  introAnimation: boolean;
  turnOffIntroAnimation: () => void;
}

export const State = createContext<StateProps>({} as StateProps);

function MainLayout({ children }: MainLayoutProps) {
  const [introAnimation, setIntroAnimation] = useState(true);

  const turnOffIntroAnimation = () => {
    setIntroAnimation(false);
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <State.Provider value={{
      turnOffIntroAnimation,
      introAnimation,
    }}
    >
      <GlobalStyle />
      {children}
    </State.Provider>
  );
}

export default MainLayout;
